@import '~bulma/bulma.sass';

body {

}

.bg {
  z-index: -1;
  position: fixed;
  top: -20px;
  bottom: -20px;
  left: -20px;
  right: -20px;
  background-image: url('./bg.jpg');
  background-size: cover;
  filter: grayscale(90%) brightness(60%) blur(2px);
}

.App {}

.updated {
  padding: 0.7em 2em;
  font-weight: bold;
  border: 1px solid rgb(0, 134, 0);
  background-color: rgb(163, 255, 163);
  position: absolute;
  z-index: 10;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.page {
  &.large {
    background-color: rgba(255, 255, 255, 0.8);
    margin-left: 2%;
    margin-right: 2%;

    box-shadow: 0px 0px 20px 1px #000;

    section {
      color: #000;
      padding: 1em;
      &.header {
        .links {
          display: none;
          padding-top: 0;
          margin-top: -1em;
          a {
            color: #222;
            font-weight: 600;
          }
        }
      }
      p {
        padding-bottom: 1em;
      }
    }

    footer {
      padding-left:3em;
      padding-right: 3em;
      padding-top: 2em;
      padding-bottom: 3em;
      background-color: rgba(0, 0, 0, 0.5);

      p {
        margin: 0;
        padding: 0;

        color: #fff;
        a {
          color: #ddd;
        }
      }
    }

    header {
      padding-left:3em;
      padding-right: 3em;
      padding-top: 1em;
      padding-bottom: 1em;
      background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        p {
          font-size: 1.5em;
        }
    }

  }

  &.simple {
    background-color: rgba(255, 255, 255, 0.8);
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    box-shadow: 0px 0px 20px 1px #000;

    section {
      color: #000;
      padding: 1em;
      &.header {
        .links {
          display: none;
          padding-top: 0;
          margin-top: -1em;
          a {
            color: #222;
            font-weight: 600;
          }
        }
      }
      p {
        padding-bottom: 1em;
      }
    }

    footer {
      padding-left:3em;
      padding-right: 3em;
      padding-top: 2em;
      padding-bottom: 3em;
      background-color: rgba(0, 0, 0, 0.5);

      p {
        margin: 0;
        padding: 0;

        color: #fff;
        a {
          color: #ddd;
        }
      }
    }

    header {
      padding-left:3em;
      padding-right: 3em;
      padding-top: 1em;
      padding-bottom: 1em;
      background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        p {
          font-size: 1.5em;
        }
    }

  }

}